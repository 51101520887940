import React from "react"
import { Link } from "gatsby"
import Pill from "./Pill"

function Category({ category, count, selected }) {
  if (!count) return null // Don't show categories without organizations
  return (
    <li className="font-sans my-1">
      <Link to={category.slug}>
        <Pill name={category.name} 
          count={count} 
          selected={selected} 
        />
      </Link>
    </li>
  )
}

export default function({ 
  categories, 
  activeCategoryId, 
}) {
  const selected = categories.find(category => category.id === activeCategoryId)
  const parent = selected?.parent || selected

  return (
    <div className="CategoryList leading-9 hidden w-1/5  mb-8 lg:block">
      <h3 className="text-sm font-mono tracking-wide mt-8 uppercase text-gray-700">
        Categories
      </h3>
      <ul>
        {categories.map(category => (
          <Category
            key={category.id}
            category={category}
            count={category.count}
            selected={category.id === parent?.id}
          />
        ))}
      </ul>
    </div>
  )
}
