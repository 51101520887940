import React from "react"
import { graphql } from "gatsby"
import { transformOrganizations, transformNavCategories } from "../utils/airtable"

import Layout from "../components/layout"
import OrganizationCard from "../components/OrganizationCard"
import IndexHeader from "../components/IndexHeader"
import { useOrganizationFilterState } from "../components/OrganizationFilter"
import SEO from "../components/seo"
import CategoryList from "../components/CategoryList"

function OrganizationsTemplate({
  data,
  pageContext: { 
    categoryId, 
    categoryName, 
  },
}) {
  const [filter, setFilter, applyFilter] = useOrganizationFilterState()
  const { organizationAddFormUrl } = data.site.siteMetadata

  const categories = transformNavCategories(data.categories.nodes)
  // console.log(categories);

  const Orgs = transformOrganizations(data.allOrganizations.nodes, categories)
  // console.log(Orgs);

  const filteredOrgs = applyFilter(Orgs)
  // console.log(filteredOrgs);
  // console.log(categoryRecId, categoryName);

  return (
    <Layout contentClassName="bg-gray-100 px-3 sm:px-6">
      <SEO title={categoryName ? `${categoryName} Organizations on DFD` : `All Organizations on DFD`} />

      <div className="flex flex-col mx-auto container lg:flex-row font-sans ">
        <CategoryList
          categories={categories}
          activeCategoryId={categoryId}
        />
        <div className="lg:w-3/5">
          <IndexHeader
            title={categoryName ? `${categoryName} Organizations on DFD` : `All Organizations on DFD`}
            buttonText="Add"
            buttonUrl={organizationAddFormUrl}
            filter={filter}
            onClearFilter={() => setFilter.none()}
            onApplyFilter={setFilter}
            organizations={filteredOrgs}
            allOrganizations={Orgs}
            showFilters={["is80g", "orgType"]}
          />

          <div>
            {filteredOrgs.map(org => (
              <OrganizationCard
                organization={org}
                // categoryId={categoryId}
                key={org.recordId}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query myQueryAndMyQuery ($categoryRecId: String)  {
    site {
      id
      siteMetadata {
        organizationAddFormUrl
      }
    }

    categories: allAirtable(filter: {table: {eq: "Categories"}}) {
      nodes {
        id
        data {
          cat_name
          Org_Data
        }
        recordId
      }
    }

    allOrganizations: allAirtable (filter: {table: {eq: "Org_Data"}, data: {Approval: {eq: "Approved"}, Categories: {eq: $categoryRecId}}}) 
    {
      nodes {
        id
        data {
          Id
          About
          Address
          Categories
          Email
          Email_Contributor
          How80g
          Is80g
          Logo {
            raw {
              url
            }
          }
          Maps
          Name
          Name_Contributor
          Orgtype
          Phone
          Tagline
          Whatsapp
          Website
          LogoURL
          Timestamp(fromNow: true)
        }
        recordId
      }
    }
  }
`

export default OrganizationsTemplate
